import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import {
  createStyles,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import slug from 'slug';

const AutoComplete = () => {

  const data = useStaticQuery(graphql`
    {
      allProduct(sort: {fields: title, order: ASC}, filter: {isFeatured: {eq: true}}) {
        nodes {
          id
          title
          slug
        }
      }
    }  
  `)

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: `${process.env.COLOR1}`,
      },
      '& label': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
    },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${process.env.COLOR1}`,
      },
      '& .MuiInputBase-input': {
        fontFamily: `${process.env.FONT_FAMILY}`,
        fontSize: `${process.env.FONT_SIZE}`
    },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${process.env.COLOR1}`,
        },
        '&:hover fieldset': {
          borderColor: `${process.env.COLOR1}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${process.env.COLOR1}`,
        },
      },
    },
  })(TextField);

  const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      margin: {
        margin: theme.spacing(1),
        width: '265px'
      },
    }),
  );

  const products = data.allProduct.nodes

  const classes = useStyles();



  const setProduct = event => {
    const productSlug = slug(event, { lower: true })
    navigate(`/produtos/${productSlug}`)
  }

  return (
    <div className={classes.root}>
      <div>
        <Autocomplete
          size="small"
          freeSolo
          onChange={(e, v) => setProduct(v)}
          options={products.map(option => option.title)}
          renderInput={params => (
            <CssTextField {...params}
              label="Pesquisar produto"
              margin="normal"
              variant="outlined"
              className={classes.margin}
            />
          )}
        />
      </div>
    </div>
  )
}

export default AutoComplete